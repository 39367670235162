import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import "./styles/editRack.css";

const EditRack = () => {
  const [formData, setFormData] = useState({
    rackNumber: '',
    cropName: '',
    transplantDate: '',
    dropboxUrl: '',
    channel_1: '',
    api_1: '',
    channel_2: '',
    api_2: ''
  });
  const [existingData, setExistingData] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [isDataFetched, setIsDataFetched] = useState(false);
  const { rackNumber } = useParams();
  const navigate = useNavigate();
  const [isFilePresent, setIsFilePresent] = useState(false);
  const [confirmationRequired, setConfirmationRequired] = useState(false);
  const [noDownloadFile, setNodownloadFile] = useState(false);
  const [fileName, setFileName] = useState("");
  useEffect(() => {
    const fetchPlantData = async () => {
      try {
        const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/plants/${rackNumber}`);
        const formattedDate = new Date(data.transplantDate).toISOString().split('T')[0];
        setFormData({
          ...data,
          transplantDate: formattedDate
        });
        setExistingData(data);
        const fileCheck = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/check-file/${rackNumber}`);
        setIsFilePresent(fileCheck.data.fileExists);
        setIsDataFetched(true);
      } catch (error) {
        setErrorMessage('Failed to fetch Rack Data');
        setIsDataFetched(false);
      }
    };

    if (rackNumber) {
      fetchPlantData();
    }
  }, [rackNumber]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const updates = {};
      Object.keys(formData).forEach(key => {
        if (formData[key] !== existingData[key]) {
          updates[key] = formData[key];
        }
      });
  
      if (Object.keys(updates).length > 0) {
        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/plants/${formData.rackNumber}/update`, updates);
        navigate('/home');
      } else {
        setErrorMessage('No changes detected');
      }
    } catch (error) {
      setErrorMessage('Failed to update plant data');
    }
  };
  const handleDelete = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setConfirmationRequired(false);
    setNodownloadFile(false);
  
    try {
      const numericRackNumber = parseInt(formData.rackNumber, 10);
      const submissionData = { rackNumber: numericRackNumber };
  
     
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/delete-rack`,
        submissionData
      );
      console.log(response.data)
      if (response.data.confirmationRequired) {
        setFileName(response.data.fileName);
        setConfirmationRequired(true);
        console.log("here");
      } else if (response.data.noDownloadFile) {
        setNodownloadFile(true);
      } else {
        navigate("/home");
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage("Deletion failed. Please try again.");
      }
    }
  };
  const handleFileDownload = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/download/${fileName}`, {
        method: 'GET',
      });

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      setErrorMessage("Failed to download file. Please try again.");
    }
  };
  const handleConfirmDeletion = async () => {
    try {
      const numericRackNumber = parseInt(formData.rackNumber, 10);
      const submissionData = { ...formData, rackNumber: numericRackNumber };

      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/confirm-deletion`, submissionData);

      navigate("/home");
    } catch (error) {
      setErrorMessage("Deletion  failed. Please try again.");
    }
  };
  const handleCloseConfirmation = () => {
    setConfirmationRequired(false);
    setNodownloadFile(false);
  };
  
  return (
    <div className="app-container">
      <div className="form-container">
        <h2>Edit Rack</h2>
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        <form onSubmit={handleSubmit}>
          <label htmlFor="rackNumber">Select Rack</label>
          <select
            name="rackNumber"
            id="rackNumber"
            value={formData.rackNumber}
            onChange={handleChange} 
            disabled
            required
          >
            <option value="" disabled>Select Rack</option>
            {[...Array(8).keys()].map((i) => (
              <option key={i + 1} value={i + 1}>Rack {i + 1}</option>
            ))}
          </select>

          <label htmlFor="cropName">Crop Name</label>
          <select
            name="cropName"
            id="cropName"
            value={formData.cropName}
            onChange={handleChange}
            required
          >
            <option value="" disabled>Select Crop</option>
            <option value="Curly Kale">Curly Kale</option>
            <option value="Salad">Salad</option>
          </select>

          <label htmlFor="transplantDate">Transplant Date</label>
          <input
            type="date"
            name="transplantDate"
            id="transplantDate"
            value={formData.transplantDate}
            onChange={handleChange}
            required
          />

          <label htmlFor="dropboxUrl">Dropbox Folder URL</label>
          <input
            type="text"
            name="dropboxUrl"
            id="dropboxUrl"
            value={formData.dropboxUrl}
            onChange={handleChange}
            required
          />

<label htmlFor="channel_1">UBIBOT Channel 1</label>
<input
  type="text"
  name="channel_1"
  id="channel_1"
  value={formData.channel_1}
  onChange={handleChange}
  placeholder="First channel number ex: '12345'"
  required
  disabled={isFilePresent}
/>

<label htmlFor="api_1">UBIBOT API KEY 1</label>
<input
  type="text"
  name="api_1"
  id="api_1"
  value={formData.api_1}
  onChange={handleChange}
  placeholder="API Key for first channel"
  required
  disabled={isFilePresent}
/>

<label htmlFor="channel_2">UBIBOT Channel 2</label>
<input
  type="text"
  name="channel_2"
  id="channel_2"
  value={formData.channel_2}
  onChange={handleChange}
  placeholder="Second channel number ex: '12345'"
  required
  disabled={isFilePresent}
/>

<label htmlFor="api_2">UBIBOT API KEY 2</label>
<input
  type="text"
  name="api_2"
  id="api_2"
  value={formData.api_2}
  onChange={handleChange}
  placeholder="API Key for second channel"
  required
  disabled={isFilePresent}
/>
<div className="button2-container">
          
          <button 
            onClick={handleDelete}
            className="delete-button"
            disabled={!isDataFetched} // Disable button if data fetch failed
          >
            Delete Rack
          </button>
          <button 
            type="submit" 
            className="update-button"
            disabled={!isDataFetched} // Disable button if data fetch failed
          >
            Update Plant
          </button>
         </div>
        </form>
      </div>
      {/* Overlay and Popup */}
      {(confirmationRequired || noDownloadFile) && (
        <div className="overlay">
          <div className="confirmation-container">
            <p>
              {confirmationRequired
                ? 'This action cannot be undone.\nDownload the file to prevent data loss. \n'
                : 'This action cannot be undone.\n No data existed.'}
            </p>
            {confirmationRequired && (
              <>
                <button onClick={handleFileDownload} className="download-link">
                  Download File
                </button>
                <button onClick={handleConfirmDeletion} className="delete-button">
                  Delete Rack
                </button>
              </>
            )}
            {!confirmationRequired && (
              <button onClick={handleConfirmDeletion} className="delete-button">
                Delete Rack
              </button>
            )}
            <button onClick={handleCloseConfirmation} className="cancel1-button">
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditRack;
