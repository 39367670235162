import React, { useContext } from 'react';
import './styles/navbar.css';
import { AuthContext } from './AuthContext';
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
  const { isAuthenticated, logout, setIntendedUrl } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/home');
  };

  const handleLogin = () => {
    navigate('/login');
  };

  const handleRegisterClick = (e) => {
    if (!isAuthenticated) {
      e.preventDefault();
      setIntendedUrl('/register-plant');
      navigate('/login');
    }
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div className="left-section-nav">
          <div className="logo-container">
            <img src='/images/Mardi-logo.png' alt="MARDI Logo" />
          </div>
          <div className="company-name">
            <span>INSTITUT PENYELIDIKAN DAN<br />KEMAJUAN PERTANIAN MALAYSIA</span>
          </div>
        </div>
        <div className="right-section-nav">
          <div className="nav-links">
            <a href="/home">DASHBOARD</a>
            <a href="/register-plant" onClick={handleRegisterClick}>REGISTER</a>
            {isAuthenticated ? (
              <button className="login-btn" onClick={handleLogout}>LOGOUT</button>
            ) : (
              <button className="login-btn" onClick={handleLogin}>LOGIN</button>
            )}
          </div>
        </div>
      </div>
      <hr className="navbar-divider" />
    </nav>
  );
};

export default Navbar;
