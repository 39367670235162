import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { AuthContext } from "./AuthContext";
import { useNavigate } from "react-router-dom";
import "./styles/registerPlant.css";

const RegisterPlant = () => {
  const [formData, setFormData] = useState({
    rackNumber: "",
    cropName: "",
    transplantDate: "",
    dropboxUrl: "",
    channel_1: "",
    api_1: "",
    channel_2: "",
    api_2: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [confirmationRequired, setConfirmationRequired] = useState(false);
  const [noDownloadFile, setNodownloadFile] = useState(false);
  const [newRegistration, setNewRegistration] = useState(false);
  const [fileName, setFileName] = useState("");
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      transplantDate: new Date().toISOString().substr(0, 10),
    }));
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setConfirmationRequired(false);
    setNodownloadFile(false);

    try {
      const numericRackNumber = parseInt(formData.rackNumber, 10);
      const submissionData = { ...formData, rackNumber: numericRackNumber };

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/register-plant`,
        submissionData
      );

      if (response.data.confirmationRequired) {
        setFileName(response.data.fileName);
        setConfirmationRequired(true);
      } else if (response.data.noDownloadFile) {
        setNodownloadFile(true);
      } else if(response.data.newRegistration) {
        setNewRegistration(true);
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage("Registration failed. Please try again.");
      }
    }
  };

  const handleFileDownload = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/download/${fileName}`, {
        method: 'GET',
      });

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      setErrorMessage("Failed to download file. Please try again.");
    }
  };

  const handleConfirmRegistration = async () => {
    try {
      const numericRackNumber = parseInt(formData.rackNumber, 10);
      const submissionData = { ...formData, rackNumber: numericRackNumber };

      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/confirm-registration`, submissionData);

      navigate("/home");
    } catch (error) {
      setErrorMessage("Confirmation failed. Please try again.");
    }
  };
  const handleNewRegistration = async () => {
    try {
      const numericRackNumber = parseInt(formData.rackNumber, 10);
      const submissionData = { ...formData, rackNumber: numericRackNumber };

      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/new-registration`, submissionData);

      navigate("/home");
    } catch (error) {
      setErrorMessage("New Registration Failed failed. Please try again.");
    }
  };

  const handleCloseConfirmation = () => {
    setConfirmationRequired(false);
    setNodownloadFile(false);
    setNewRegistration(false);
  };

  return (
    <div className="app-container">
      <div className="form-container">
        <h2>Register Plant</h2>
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        <form onSubmit={handleSubmit}>
          <label htmlFor="rackNumber">Select Rack</label>
          <select
            name="rackNumber"
            id="rackNumber"
            value={formData.rackNumber}
            onChange={handleChange}
            required
          >
            <option value="" disabled>
              Select Rack
            </option>
            {[...Array(8).keys()].map((i) => (
              <option key={i + 1} value={i + 1}>Rack {i + 1}</option>
            ))}
          </select>
  
          <label htmlFor="cropName">Crop Name</label>
          <select
            name="cropName"
            id="cropName"
            value={formData.cropName}
            onChange={handleChange}
            required
          >
            <option value="" disabled>
              Select Crop
            </option>
            <option value="Curly Kale">Curly Kale</option>
            <option value="Salad">Salad</option>
          </select>
  
          <label htmlFor="transplantDate">Transplant Date</label>
          <input
            type="date"
            name="transplantDate"
            id="transplantDate"
            value={formData.transplantDate}
            onChange={handleChange}
            required
          />
  
          <label htmlFor="dropboxUrl">Dropbox Folder URL</label>
          <input
            type="text"
            name="dropboxUrl"
            id="dropboxUrl"
            value={formData.dropboxUrl}
            onChange={handleChange}
            required
          />
  
          <label htmlFor="channel_1">UBIBOT Channel 1</label>
          <input
            type="text"
            name="channel_1"
            id="channel_1"
            value={formData.channel_1}
            onChange={handleChange}
            placeholder="First channel number ex: '12345'"
            required
          />
          <label htmlFor="api_1">UBIBOT API KEY 1</label>
          <input
            type="text"
            name="api_1"
            id="api_1"
            value={formData.api_1}
            onChange={handleChange}
            placeholder="API Key for first channel"
            required
          />
          <label htmlFor="channel_2">UBIBOT Channel 2</label>
          <input
            type="text"
            name="channel_2"
            id="channel_2"
            value={formData.channel_2}
            onChange={handleChange}
            placeholder="Second channel number ex: '12345'"
            required
          />
          <label htmlFor="api_2">UBIBOT API KEY 2</label>
          <input
            type="text"
            name="api_2"
            id="api_2"
            value={formData.api_2}
            onChange={handleChange}
            placeholder="API Key for second channel"
            required
          />
  
          <button type="submit" className="register-button">
            Register Plant
          </button>
        </form>
      </div>
  
     {/* Overlay and Popup */}
{(confirmationRequired || noDownloadFile || newRegistration) && (
  <div className="overlay">
    <div className="confirmation-container">
      <p>
        {confirmationRequired
          ? 'Rack number already exists.\nDownload the file to prevent data loss. \nThis action cannot be undone.'
          : noDownloadFile
          ? 'Rack number already exists.\nNo data existed. \nThis action cannot be undone.'
          : 'Please verify the details before confirming.'}
      </p>
      {confirmationRequired && (
        <>
          <button onClick={handleFileDownload} className="download-link">
            Download File
          </button>
          <button onClick={handleConfirmRegistration} className="confirm-button">
            Confirm Registration
          </button>
        </>
      )}
      {!confirmationRequired && !newRegistration && (
        <button onClick={handleConfirmRegistration} className="confirm-button">
          Confirm Registration
        </button>
      )}
      {newRegistration && (
        <>
          <button onClick={handleNewRegistration} className="confirm-button">
            Confirm
          </button>
          <button onClick={handleCloseConfirmation} className="cancel-button">
            Cancel
          </button>
        </>
      )}
      <button onClick={handleCloseConfirmation} className="cancel-button">
        Cancel
      </button>
    </div>
  </div>
)}
    </div>
  );
};

export default RegisterPlant;
