import React, { useContext, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Navbar from './Navbar';
import Middle from './Middle';
import Signup from './Signup';
import Login from './Login';
import RegisterPlant from './RegisterPlant';
import EditRack from './EditRack';
import { AuthProvider, AuthContext } from './AuthContext';
import ProtectedRoute from './ProtectedRoute';
import { isTokenExpired } from './utils/checkTokenExpiration';
import {getDropboxAccessToken} from './utils/getDropboxAccessToken'

function App() {
  const { isAuthenticated, logout, setIsAuthenticated } = useContext(AuthContext);

  useEffect(() => {
    const token = localStorage.getItem('authToken');

    if (token) {
      if (isTokenExpired(token)) {
        logout();
      } else {
        setIsAuthenticated(true);
      }

      const intervalId = setInterval(() => {
        if (isTokenExpired(token)) {
          logout();
        }
      }, 1000 * 60); // Check every minute

      return () => clearInterval(intervalId);
    } else {
      setIsAuthenticated(false);
    }
  }, [logout, setIsAuthenticated]);

  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/signup" element={<Signup />} />
          <Route path="/login" element={<Login />} />
          <Route path="/home" element={<Middle />} />
          <Route path="/register-plant" element={<ProtectedRoute element={<RegisterPlant />} />} />
          <Route path="/edit-rack/:rackNumber" element={<ProtectedRoute element={<EditRack />} />} />
          <Route path="/" element={<Navigate to="/home" />} />
        </Routes>
      </div>
    </Router>
  );
}

export default function AppWrapper() {
  return (
    <AuthProvider>
      <App />
    </AuthProvider>
  );
}
