import React, { useState, useContext } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { AuthContext } from './AuthContext';
import { useNavigate } from 'react-router-dom';

const Signup = () => {
  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');

    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/signup`, {
        ...formData,
      });

      // Login the user automatically after successful signup
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/login`, {
        username: formData.username,
        password: formData.password,
      });
      const token = response.data.token;

      login(token);
      navigate('/home');
    } catch (error) {
      if (error.response && error.response.status === 400) {
        if (error.response.data.error === 'Username already taken') {
          setErrorMessage('This username is already taken, choose another one.');
        } 
         else {
          setErrorMessage('Signup failed. Please try again.');
        }
      } else {
        setErrorMessage('Signup failed. Please try again.');
      }
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="form-container">
      <h2>Sign Up</h2>
      {errorMessage && <div className="error-message">{errorMessage}</div>}
      <form onSubmit={handleSubmit}>
       
        <label htmlFor="username">Username</label>
        <input
          type="text"
          name="username"
          id="username"
          value={formData.username}
          onChange={handleChange}
          required
        />
        
        <label htmlFor="password">New Password</label>
        <div className="password-container">
          <input
            type={showPassword ? "text" : "password"}
            name="password"
            id="password"
            value={formData.password}
            onChange={handleChange}
            required
          />
          <button
            type="button"
            onClick={togglePasswordVisibility}
            className="password-toggle"
          >
            <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
          </button>
        </div>
        <button type="submit" className="login-button">Create Account</button>
      </form>
      <hr className="divider" />
      <p className="account-prompt">Already have an account?</p>
      <button
        className="create-account-button"
        onClick={() => navigate('/login')}
      >
        Sign In
      </button>
    </div>
  );
};

export default Signup;
