import React, { useState, useEffect,useContext } from "react";
import axios from "axios";
import "./styles/middle.css";
import Rack from "./Rack";
import { useNavigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';

const Middle = () => {
  const [transplantDate, setTransplantDate] = useState("");
  const [rack, setRack] = useState("");
  const [availableRacks, setAvailableRacks] = useState([]);
  const [crop, setCrop] = useState("");
  const [availableCrops, setAvailableCrops] = useState([]);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false); // New loading state
  const [isLoading2, setIsLoading2] = useState(false); // New loading state
  const [cropAge, setCropAge] = useState("");
  const [imageName, setImageName] = useState("");
  const [responseAI, setResponseAI] = useState(null);
  const [JobID, setJobID] = useState(null);
  const [JobResponse, setJobResponse] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [currentPlant, setCurrentPlant] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [dropboxErrorMessage, setDropboxErrorMessage] = useState("");
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const navigate = useNavigate();
  const { isAuthenticated, logout, setIntendedUrl } = useContext(AuthContext);
  

  useEffect(() => {
    setResponseAI(null);
    setJobID(null);
    setJobResponse(null);
    setImageSrc(null);
    setIsButtonClicked(false);
    const checkDropboxAuth = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/dropbox-access-token`
        );
        if (!response.data.access_token) {
          handleDropboxAuth();
        } else {
          fetchAvailableRacks();
        }
      } catch (error) {
        handleDropboxAuth();
      }
    };

    checkDropboxAuth();
  }, []);
  useEffect(() => {
    // Fetch available racks when component mounts
    fetchAvailableRacks();
  }, []);

  useEffect(() => {
    setResponseAI(null);
    setJobID(null);
    setJobResponse(null);
    setImageSrc(null);
    setIsButtonClicked(false);
    // Fetch available crops whenever the rack is changed
    if (rack) {
      fetchAvailableCrops(rack);
    } else {
      resetPlantData();
    }
  }, [rack]);

  useEffect(() => {
    // Fetch plant data whenever the rack or crop is changed
    if (rack && crop) {
      fetchPlantData(rack, crop);
    } else {
      resetPlantData();
    }
  }, [rack, crop]);

  useEffect(() => {
    if (JobID) {
      const interval = setInterval(async () => {
        // console.log("Fetching from Job ID: ", JobID);
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/status/${JobID}`
          );
          setJobResponse(response.data);
          // console.log(response.data);
          setIsLoading2(false);
          if (response.data.progress === 100) {
            clearInterval(interval);
          }
        } catch (error) {
          console.error("Error fetching job status:", error);
        }
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [JobID]);

  const fetchAvailableRacks = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/racks`
      );
      setAvailableRacks(response.data);
    } catch (error) {
      console.error("Error fetching available racks:", error);
    }
  };

  const fetchAvailableCrops = async (rack) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/racks/${rack}/crops`
      );
      setAvailableCrops(response.data);

      setCrop(response.data[0]);
    } catch (error) {
      console.error("Error fetching available crops:", error);
    }
  };

  const fetchPlantData = async (rack, crop) => {
    setUploadedImage(null);
    setIsLoading(true); // Start loading spinner
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/plants`,
        {
          params: { rack, crop },
        }
      );
      const plantData = response.data;
      setCurrentPlant(plantData);
      if (plantData) {
        setTransplantDate(plantData.transplantDate.substring(0, 10)); // "yyyy-MM-dd"
        const latestImageUrl = await fetchLatestImageFromDropbox(
          plantData.dropboxUrl
        );
        if (latestImageUrl) {
          preloadImage(latestImageUrl);
        } else {
          console.error("Latest image URL is not available.");
          
        }
        setImageName(plantData.cropName);
        calculateCropAge(plantData.transplantDate);
      }
    } catch (error) {
      console.error("Error fetching plant data:", error);
      resetPlantData();
    } finally {
      //setIsLoading(false); // Stop loading spinner
    }
  };

  const preloadImage = (url) => {
    const img = new Image();
    img.src = url;

    img.onload = () => {
      setUploadedImage(url);
      setIsLoading(false); // Stop loading spinner after image loads
     
    };

    img.onerror = () => {
      console.error("Error loading image.");
      setDropboxErrorMessage(`Error loading image.`);
      setUploadedImage(null);
      setIsLoading(false)
    };
  };

  const fetchLatestImageFromDropbox = async (dropboxUrl) => {
    try {
      const tokenResponse = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/dropbox-access-token`
      );
      const accessToken = tokenResponse.data.access_token;

      // Get shared link metadata to resolve the actual path
      const metadataResponse = await axios.post(
        "https://api.dropboxapi.com/2/sharing/get_shared_link_metadata",
        { url: dropboxUrl },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      const { path_lower } = metadataResponse.data;
      if (!path_lower)
        throw new Error("Could not resolve the folder path from Dropbox URL.");

      // List the contents of the resolved folder path
      const listFolderResponse = await axios.post(
        "https://api.dropboxapi.com/2/files/list_folder",
        { path: path_lower },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!listFolderResponse.data.entries)
        throw new Error("No entries found in the resolved Dropbox folder.");

      // Filter image files
      const imageEntries = listFolderResponse.data.entries.filter(
        (entry) =>
          entry[".tag"] === "file" && entry.name.match(/\.(jpg|jpeg|png|gif)$/i)
      );
      if (imageEntries.length === 0)
        throw new Error("No images found in the resolved Dropbox folder.");

      // Sort entries by modification date
      imageEntries.sort(
        (a, b) => new Date(b.client_modified) - new Date(a.client_modified)
      );
      const latestImage = imageEntries[0];

      // Get temporary link for the latest image
      const tempLinkResponse = await axios.post(
        "https://api.dropboxapi.com/2/files/get_temporary_link",
        { path: latestImage.path_lower },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      return tempLinkResponse.data.link;
    } catch (error) {
      console.error("Error fetching the latest image from Dropbox:", error);
      setDropboxErrorMessage(`Error fetching the image from Dropbox: ${error.message}`);
      setUploadedImage(null);
      setIsLoading(false)
      
      return null;
    }
  };

  const resetPlantData = () => {
    setTransplantDate("");
    setUploadedImage(null);
    setImageName("");
    setCropAge("");
    setDropboxErrorMessage("");
    
  };

  const calculateCropAge = (transplantDate) => {
    const today = new Date();
    const transplant = new Date(transplantDate);
    const diffTime = Math.abs(today - transplant);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    const diffWeeks = Math.floor(diffDays / 7);
    setCropAge(`Week ${diffWeeks}`);
  };
  const handleDateChange = (e) => {
    const newDate = e.target.value;
    setTransplantDate(newDate);
    calculateCropAge(newDate);
  };
  const handleRackChange = (e) => {
    setRack(e.target.value);
    setCrop("");
    setIsLoading(false);
    setUploadedImage(null);
  };

  const handleCropChange = (e) => {
    setCrop(e.target.value);
  };

  const handleEdit = (e) => {
    if (!isAuthenticated) {
      e.preventDefault();
      setIntendedUrl(`/edit-rack/${rack}`);
      navigate('/login');
    }
    else{
      navigate(`/edit-rack/${rack}`);
    }
  };
  const handleDropboxAuth = () => {
    window.location.href = `${process.env.REACT_APP_BACKEND_URL}/dropbox-auth-start`;
  };

  const handleGenerate = async () => {
    setIsLoading2(true);
    setErrorMessage("");
    setDropboxErrorMessage("");
    setResponseAI("");
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/detect`,
        {
          image_url: uploadedImage,
        }
      );
      setResponseAI(res.data);
      setJobID(res.data.id);
      // console.log(res.data.id);
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage(
        error.message,
        " Please check your network and try again."
      );
      setIsLoading2(false);
      setResponseAI("An error occurred");
    }
  };

  const handleShowImage = () => {
    if (JobResponse) {
      if (JobResponse.progress == 100)
        try {
          const imageSrc = `data:image/jpeg;base64,${JobResponse.image_result}`;
          setImageSrc(imageSrc);
          setIsButtonClicked(true);
        } catch (error) {
          console.log(error);
        }
    }
  };

  return (
    <div className="middle-container">
      <div className="top-row">
        <div className="control-group">
          <label htmlFor="rackNumber">Rack Number</label>
          <select id="rackNumber" value={rack} onChange={handleRackChange}>
            <option value="">Select Rack</option>
            {availableRacks.map((rackNumber) => (
              <option
                key={rackNumber}
                value={rackNumber}
              >{`Rack ${rackNumber}`}</option>
            ))}
          </select>
        </div>

        <div className="control-group">
          <label htmlFor="cropName">Crop Name</label>
          <select
            id="cropName"
            value={crop}
            onChange={handleCropChange}
            disabled
          >
            <option value="">Select Crop</option>
            {availableCrops.map((cropName) => (
              <option key={cropName} value={cropName}>
                {cropName}
              </option>
            ))}
          </select>
        </div>

        <div className="control-group">
          <label htmlFor="transplantDate">Transplant Date</label>
          <input
            type="date"
            id="transplantDate"
            value={transplantDate}
            onChange={handleDateChange}
            disabled
          />
        </div>
      </div>
      <div className="button-container">
      {rack && <button
              className="edit-btn"
              onClick={handleEdit}
              disabled={!rack}
            >
              Edit Rack
            </button>}
      </div>
      {isLoading ? (
  <div className="loading-spinner"></div>
) : (
  <div className="bottom-row">
    <div className="left-section">
      {rack &&uploadedImage&& !dropboxErrorMessage ? (
        <>
          <img
            src={uploadedImage}
            alt="Crop Image"
            className="crop-image"
          />
          <p className="image-name">{imageName}</p>
          <p className="crop-age">Actual Crop Age: {cropAge}</p>
          <div className="generate-section">
            <p className="crop-age-image">
              AI Predicted Crop Age:{" "}
              {JobResponse
                ? JobResponse.progress === 100
                  ? JobResponse.age
                  : "Generating..."
                : "Unknown"}
            </p>
            <button
              className="generate-btn"
              onClick={handleGenerate}
              disabled={responseAI !== null}
            >
              Generate
            </button>
          </div>
          {isLoading2 ? (
            <div className="loading-spinner"></div>
          ) : (
            JobResponse && (
              <div>
                <br />
                {!errorMessage && (
                  <div>
                    <div className="progress-container">
                      <div>
                        Loading... Current Progress:{" "}
                        {JSON.stringify(JobResponse.progress, null, 2) == null
                          ? "0"
                          : JSON.stringify(JobResponse.progress, null, 2)}
                        %
                      </div>
                      {JobResponse.progress !== 100 && (
                        <div className="small-loading-spinner"></div>
                      )}
                    </div>
                    <div>
                      Last updated at:{" "}
                      {new Date(JobResponse.updated_at).toLocaleTimeString(
                        "en-US",
                        {
                          hour: "2-digit",
                          minute: "2-digit",
                          second: "2-digit",
                        }
                      )}{" "}
                    </div>
                  </div>
                )}
              </div>
            )
          )}
          <br />
          {errorMessage && (
            <div className="error-message">{errorMessage}</div>
          )}
          {!errorMessage &&
            JobResponse &&
            JobResponse.progress === 100 && (
              <button
                className="generate-btn"
                onClick={handleShowImage}
                disabled={JobResponse.progress !== 100 || isButtonClicked}
              >
                Show Identification Image
              </button>
            )}
          <br />
          {imageSrc && (
            <div>
              <h2>Identified Image:</h2>
              <img
                src={imageSrc}
                style={{ width: "100%" }}
                alt="Identified"
              />
            </div>
          )}
        </>
      ) : null}
      {dropboxErrorMessage && (
            <div className="error-message">{dropboxErrorMessage}</div>
          )}
    </div>

    <div className="right-section">
     {rack && <Rack rack={rack} currentPlant={currentPlant} JobResponse={JobResponse}/>}
    </div>
  </div>
)}

 

    </div>
  );
};

export default Middle;
