// models/Token.js
const mongoose = require('mongoose');

const tokenSchema = new mongoose.Schema({
  type: { type: String, required: true }, // e.g., 'dropbox'
  accessToken: { type: String, required: true },
  refreshToken: { type: String, required: true },
  expiresIn: { type: Number, required: true }, // e.g., 3600
  updatedAt: { type: Date, default: Date.now }
});

module.exports = mongoose.model('Token', tokenSchema);
