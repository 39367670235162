// utils/getDropboxAccessToken.js
const axios = require('axios');
const Token = require('../Token.js');

const getDropboxAccessToken = async () => {
  const token = await Token.findOne({ type: 'dropbox' });
  if (!token) throw new Error('Dropbox token not found');

  const now = new Date();
  const expiresAt = new Date(token.updatedAt.getTime() + token.expiresIn * 1000);

  if (now >= expiresAt) {
    // Refresh the token
    const response = await axios.post('https://api.dropbox.com/oauth2/token', null, {
      params: {
        refresh_token: token.refreshToken,
        grant_type: 'refresh_token',
        client_id: process.env.DROPBOX_APP_KEY,
        client_secret: process.env.DROPBOX_APP_SECRET,
      },
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    const { access_token, expires_in } = response.data;
    token.accessToken = access_token;
    token.expiresIn = expires_in;
    token.updatedAt = new Date();
    await token.save();
  }

  return token.accessToken;
};

module.exports = getDropboxAccessToken;
